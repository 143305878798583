/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Recharge',
  data: function data() {
    return {
      //input提示语
      placeholder: '',
      //金额input输入框状态
      moneyDisabled: false,
      //充值金额
      rechargeValue: '',
      //充值金额数组
      moneyData: [],
      amount: '',
      //总数
      //选中的充值金额，默认不选中
      mySelected: -1,
      setting: {},
      jiaoyanguizi: ''
    };
  },
  watch: {
    // 获取输入充值金额
    rechargeValue: function rechargeValue(val) {
      var _this = this;

      // 输入值不为空且值小于0
      if (val !== '' && val < 0) {
        // 将输入值清空
        this.rechargeValue = ''; // 提示输入正确的金额

        this.$utils.Tools.toastShow('请输入正确的金额');
      } else {
        // 输入充值不为空且符合要求   将选中的充值金额设置为不选中
        this.mySelected = -1;
        this.moneyData.filter(function (money, index) {
          if (+money.value === +val) {
            _this.mySelected = index;
          }
        }); // 如果输入金额大于定义最大充值金额

        if (val > this.amount) {
          // 将输入金额设置为定义的最大充值金额
          this.rechargeValue = this.amount; // 提示最大充值金额数

          this.$utils.Tools.toastShow("\u6700\u5927\u5145\u503C\u91D1\u989D\u4E3A".concat(this.amount, "\u5143"));
        }
      }
    }
  },
  computed: {
    isDisabled: function isDisabled() {
      return !(this.rechargeValue !== '' && this.rechargeValue > 0 && this.rechargeValue <= this.amount);
    }
  },
  methods: {
    // 选中充值数
    moneyClick: function moneyClick(obj, index) {
      this.mySelected = index;
      this.rechargeValue = obj.value;
    },
    // 点击充值记录按钮 跳转充值记录页面
    showMore: function showMore() {
      // this.$router.push('/RechargeRecord')
      // this.$router.push('/SpendDetail')
      this.$router.replace({
        path: '/SpendDetail',
        query: {
          active: 1
        }
      });
    },
    //校验输入的充值金额是否符合要求
    checkValue: function checkValue(val) {
      if (val != '') {
        if (Number(val) > +this.amount) {
          this.rechargeValue = this.amount;
          this.$utils.Tools.toastShow("\u4E0D\u53EF\u5145\u503C\u8D85\u8FC7".concat(this.amount, "\u5143"));
        } else {
          this.rechargeValue = '';
          this.$utils.Tools.toastShow('请输入正确的金额');
        }
      }
    },
    judgeCHongzhi: function judgeCHongzhi(aa, bb) {
      var str = '';

      switch (aa) {
        case "请输入整数金额":
          str = '' + this.rechargeValue;

          if (str.indexOf('.') !== -1) {
            var arr = str.split('');
            arr.splice(arr.length - 1);
            var str2 = arr.join('');
            this.rechargeValue = +str2;
          }

          break;

        case "请输入金额(可保留一位小数)":
          this.rechargeValue = this.rechargeValue.match(/^\d*(\.?\d{0,1})/g)[0] || null; //重新赋值给input

          break;

        case "请输入金额(可保留俩位小数)":
          bb = bb.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
          this.rechargeValue = bb;
          break;
      }
    },
    // 查询显示哪个模块
    getRefund: function getRefund() {
      var _this2 = this;

      this.$api.Spend.getRefund().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          var tast = data.defaultAmt;
          var nunmoney = tast ? tast.split(",") : '';

          for (var i = 0; i < nunmoney.length; i++) {
            var yuan = {
              title: nunmoney[i],
              value: nunmoney[i]
            };
            _this2.moneyData[i] = yuan;
          }

          _this2.amount = data.rechargeamt; //cashLimit为后台限制的状态 cashLimint=0时候选择输入金额 输入框不用

          data.cashLimit && data.cashLimit == 0 ? _this2.placeholder = '请选择充值金额' : '';
          data.cashLimit && data.cashLimit == 0 ? _this2.moneyDisabled = true : ''; // cashLimint != 0时候 输入金额 输入框可用

          data.cashLimit && data.cashLimit == 1 ? _this2.placeholder = '请输入整数金额' : '';
          data.cashLimit && data.cashLimit == 2 ? _this2.placeholder = '请输入金额(可保留一位小数)' : '';
          data.cashLimit && data.cashLimit == 3 ? _this2.placeholder = '请输入金额(可保留两位小数)' : ''; // this.amount = data.rechargeamt || 500

          _this2.setting = data || {};
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    // 钉钉环境下的设置
    setDtConfig: function setDtConfig() {
      var _this3 = this;

      this.$api.DingTalk.payDtOrderTemp({
        userSerial: this.$utils.Store.getItem('userSerial'),
        addMoney: this.rechargeValue,
        openId: this.$utils.Store.getItem('openId')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          var div = document.createElement('div');
          /* 此处form就是后台返回接收到的数据 */

          div.innerHTML = data.resp;
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // 钉钉充值
    dtRecharge: function dtRecharge() {
      var _this4 = this;

      this.$api.DingTalk.payDtOrder({
        userSerial: this.$utils.Store.getItem('userSerial'),
        addMoney: this.rechargeValue,
        openId: this.$utils.Store.getItem('openId')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          var that = _this4;

          _this4.$dd.ready(function () {
            that.$dd.biz.alipay.pay({
              info: data.resp,
              // 订单信息，
              onSuccess: function onSuccess(result) {
                if (result.resultStatus === '9000') {
                  that.$router.push({
                    path: '/PayResult',
                    query: {
                      code: that.$code.success,
                      outTradeNo: data.tradeNo
                    }
                  });
                } else {
                  that.$router.push({
                    path: '/PayResult',
                    query: {
                      code: that.$code.fail
                    }
                  });
                }
              },
              onFail: function onFail() {
                that.$router.push({
                  path: '/PayResult',
                  query: {
                    code: that.$code.fail
                  }
                });
              }
            });
          });
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    //微信支付
    wxRecharge: function wxRecharge() {
      var _this5 = this;

      var that = this;
      var money = Math.floor(parseFloat((this.rechargeValue || 0) * 10000, 10).toFixed(2) / 100);
      this.$api.Home.recharge({
        userSerial: this.$utils.Store.getItem('userSerial'),
        addMoney: money,
        openId: this.$utils.Store.getItem('openId')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          var params = {
            appId: data.appId,
            //公众号名称，由商户传入
            timeStamp: data.timeStamp,
            //时间戳，自1970年以来的秒数
            nonceStr: data.nonceStr,
            //随机串
            package: data.package,
            signType: data.signType,
            //微信签名方式：
            paySign: data.paySign //微信签名

          };
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', params, function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // 支付成功之后，跳转到支付详细信息页面
              that.$router.push({
                path: '/PayResult',
                query: {
                  code: that.$code.success,
                  outTradeNo: data.out_trade_no
                }
              });
            } else {
              // 支付失败
              that.$router.push({
                path: '/PayResult',
                query: {
                  code: that.$code.fail
                }
              });
            }
          });
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    //微信小程序充值
    onBridgeReady: function onBridgeReady() {
      var _this6 = this;

      this.$utils.Tools.getEnv(function (res) {
        // 微信小程序环境 带参进入微信小程序的逻辑
        if (res === 0) {
          _this6.$wechat.miniProgram.navigateTo({
            url: '../recharge/recharge?money=' + _this6.rechargeValue
          });
        } else {
          _this6.wxRecharge();
        }
      });
    },
    //点击支付按钮
    recharge: function recharge() {
      var _this7 = this;

      //是否允许充值
      if (this.setting.recharge != 1) {
        this.$utils.Tools.toastShow("\u8BE5\u529F\u80FD\u672A\u5F00\u542F\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458!");
        return;
      }

      var reg = /(^[1-9][0-9]{0,4}$)|(^[0][.]{1}[1-9]{1}$)|(^[0][.]{1}[0-9]{1}[1-9]{1}$)|(^[1-9][0-9]{0,4}[.]{1}[0-9]{0,2}$)/; //正则校验输入金额是否是数字格式，且整数位不大于5位，小数位不大于2位

      if (reg.test(this.rechargeValue)) {
        //获取当前环境
        //  getEnv: function (callback) {
        //     let userAgent = window.navigator.userAgent.toLowerCase();
        //     if (userAgent.indexOf('miniprogram') > -1) {
        //     //微信小程序
        //     callback(0);
        //     } else if (userAgent.indexOf('micromessenger') > -1) {
        //     //微信公众号
        //     callback(1);
        //     } else if(dd.env.platform != 'notInDingTalk') {
        //     //钉钉
        //     callback(2);
        //     }else{
        //         callback(3);
        //     }
        // },
        this.$utils.Tools.getEnv(function (res) {
          //微信/微信小程序 环境
          if (res === 0 || res === 1) {
            if (typeof WeixinJSBridge == 'undefined') {
              // attachEvent()：标准的绑定事件监听函数的方法，是W3C所支持的，Chrome、FireFox、Opera、Safari、IE9.0及其以上版本都支持该函数
              if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', _this7.onBridgeReady, false);
              } else if (document.attachEvent) {
                //attachEvent()：8.0及其以下版本支持的绑定事件监听函数
                document.attachEvent('WeixinJSBridgeReady', _this7.onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', _this7.onBridgeReady);
              }
            } else {
              _this7.onBridgeReady();
            }
          } else {
            //钉钉环境
            _this7.setDtConfig();
          }
        });
      } else {
        this.$utils.Tools.toastShow('必须为数字格式，且金额的整数位数最多5位，小数位最多2位');
      }
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    var _this8 = this;

    var localUrl = window.location.href.split('#')[0];
    this.getRefund();
    this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
      var code = response.code,
          msg = response.msg,
          data = response.data;

      _this8.$utils.Tools.loadHide();

      if (+code !== _this8.$code.success) {
        _this8.$utils.Tools.toastShow(msg);
      } else {
        _this8.$utils.WeiXin.wxConfig(data);
      }
    }).catch(function () {
      _this8.$utils.Tools.httpError();
    });
  },
  mounted: function mounted() {}
};